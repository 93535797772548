/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-01-12 21:39:19
 * @LastEditTime: 2022-01-04 14:34:57
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/jurisdiction.js
 */
import request from '@/utils/request'

export function jurisdictionList(param) {
    return request({
        url: '/admin/role/findRoles',
        method: 'post',
        data: {}
    })
}

export function addRole(param) {
    return request({
        url: '/admin/role/add',
        method: 'post',
        data: {
            name: param,
        }
    })
}

export function editRole(param) {
    return request({
        url: '/admin/role/edit',
        method: 'post',
        data: {
            name: param.name,
            id: param.id,
        }
    })
}

export function resourceList(param) {
    return request({
        url: '/admin/role/resourceList',
        method: 'post',
        data: param
    })
}

export function entrustResource(param) {
    return request({
        url: '/admin/role/entrustResource',
        method: 'post',
        data:param
    })
}

export function roleSelectList() {
    return request({
        url: '/admin/role/selectList',
        method: 'post',
        data:{
            type:'2'
        }
    })
}
export function getPermitMenuList(param) {
    return request({
        url: '/admin/menu/findPermitMenuList',
        method: 'post',
        data:{
            roleCode:param
        }
    })
}
export function editRelation(param) {
    return request({
        url: '/admin/menu/role/batchEditRelation',
        method: 'post',
        data:param
    })
}

export function editEnabled(param) {
    return request({
        url: '/admin/role/setEnabled',
        method: 'post',
        data:param
    })
}